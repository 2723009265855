// src/Profile.js
import React from 'react';
import './Profile.css'; // Import the CSS file for styling
import profilePic from './assets/profile-pic.jpeg';

const Profile = () => {
  return (
    <div className="profile-container">
      <img src={profilePic} alt="Profile" className="profile-picture" />
      <div className="profile-info">
        <h1>Markus</h1>
        <ul>
          <li>Biggest skibidi</li>
        </ul>
      </div>
    </div>
  );
};

export default Profile;
